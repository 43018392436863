.issueCard {
  position: relative;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  background-color: #2b2b2b;
  border-color: #3f3f3f;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
}

.table {
  cursor: pointer;
}

.table:hover {
  background-color: #3f3f3f !important;
}
