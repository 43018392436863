.header {
  padding: 10px;
  border-width: 1px;
  border-style: solid;
  border-image: initial;
  border-radius: 4px;
  background-color: #2b2b2b;
  border-color: #3f3f3f;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin: 10px auto;
}

.post {
  background-color: #2b2b2b;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
}

.replies {
  margin-left: 10%;
}

.reply {
  border-left-color: #2b2b2b;
  border-left-width: 2px;
  border-left-style: solid;
  padding-left: 10px;
  margin: 10px 0;
}

.postComment {
  margin: 10px 0;
  display: inline-flex;
  width: 100%;
}
