.narrow {
  max-width: 900px;
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.welcome.page > .narrow > img {
  margin: 0 auto;
  display: block;
  width: 200px;
}

.welcome.page > .narrow > ul {
  width: 80%;
  justify-content: space-between;
  padding-top: 40px;
  border-bottom: none;
}

.welcome.page > .narrow > ul > li {
  margin: auto;
}

.welcome.page > .narrow > ul > li > a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.nav {
  position: fixed;
  width: 100%;
  padding: 10px 40px;
  top: 0;
  z-index: 10;
  background-color: #121212;
  display: inline-flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .nav {
    display: inline-grid;
    justify-content: center;
  }
}

.center {
  text-align: center;
}

.sections > * {
  margin: 4rem;
}

@media screen and (max-width: 768px) {
  .sections > * {
    margin: 6rem 1rem;
  }
}

pre,
div.error {
  padding: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 1rem 0;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
}

pre.fixed,
div.error.fixed {
  height: 200px;
}

code {
  display: inline-block;
  padding: 0px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

.error {
  color: red;
}

.home {
  padding: 10px;
}
